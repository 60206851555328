import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ContactForm from "../../../components/contact-us-form"
import PageHeader from "../../../components/page-header"
import PracticeAreas from "../../../components/practice-areas"

export default () => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="Chapter 7 Bankruptcy Lawyers | RWK Attorneys" defer={false} />


		<PageHeader>
			<Row>
				<Col>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb pb-0">
							<li class="breadcrumb-item"><Link to="/">Home</Link></li>
							<li class="breadcrumb-item"><Link to="/practice-areas">Practice Areas</Link></li>
							<li class="breadcrumb-item"><Link to="/practice-areas/bankruptcy">Bankruptcy</Link></li>
							<li class="breadcrumb-item active" aria-current="page">Chapter 7</li>
						</ol>
					</nav>
					<h1>Chapter 7<br /><small>Bankruptcy Law</small></h1>
				</Col>
			</Row>
		</PageHeader>


		<Container className="my-5">
			<Row>
				<Col md="3">
					<PracticeAreas />

					<ContactForm />
				</Col>
				<Col>
					<h1>Chapter 7 Bankruptcies</h1>

					<p>Chapter 7 bankruptcy allows you to eliminate your debt without needing to repay it. Chapter 7 bankruptcy is often called "straight bankruptcy" because the process of eliminating your debt in a chapter 7 case is very straightforward. In most cases, most people are able to keep their homes and cars and other property while still eliminating their debt to credit cards, personal loans, medical bills, and payday loans.</p>

					<p>This type of bankruptcy is designed to wipe out your debt; however, this is conditioned upon you not owning any property that is not exempt. Non-exempt property will be sold by the bankruptcy court, converted to cash, and then used to pay back all or a portion of the debt that is owed by you.</p>

					<p>The bankruptcy law allows you to exempt certain property, whether it be a home, car, 401(k), or other property. If your property is exempt, it generally cannot be liquidated to repay your creditors. The exemptions differ for each piece of property that you own, and we will be able to explain to you in more detail about these exemptions.</p>

					<h3 className="mt-5">FAQ</h3>
					<p className="lead">What is a discharge?</p>
					<p>A discharge is a court order which prohibits the collection of all debt which you listed in your bankruptcy petition.&nbsp; The discharge follows and protects you the rest of your life.&nbsp; There are certain debts that are excepted from discharge and that you still must pay despite a discharge in your bankruptcy case.&nbsp; Three of the most common debts that you still must pay after a discharge are federal and state income taxes, student loans, and child support and alimony.&nbsp; Another type of debt which you must repay despite receiving a discharge is debt that you have reaffirmed, such as a house or car.</p>
					<p className="lead">Will I be able to get rid of property I no longer want or can afford?</p>
					<p>In general, the answer is yes.&nbsp; Some debtors can no longer afford their house or car payments.&nbsp; In other cases, the house is just too big for them to keep up and they want to downsize.&nbsp; In still other cases, a car may keep breaking down and it is not worthwhile to continue to repair.&nbsp; Regardless of the circumstances, you can still surrender the property back to the mortgage company or to the lienholder.&nbsp; If you do so, the balance of the debt will be wiped out (discharged).</p>
					<p className="lead">What is credit counseling?</p>
					<p>Credit counseling is required to be completed by you before you file a bankruptcy case.&nbsp; In order to complete credit counseling, you will need to fill out a form, detailing your income, monthly household expenses, and the debts which you owe.&nbsp; Credit counseling is done at our office and before you sign your petition.&nbsp; The cost of the credit counseling is $50.00.</p>
					<p className="lead">When will my bankruptcy case be filed?</p>
					<p>Your bankruptcy case will be filed once you have paid the entire amount of attorney’s fees, court costs, credit counseling fee, and any applicable credit report fee.&nbsp; Upon payment in full, you will schedule a time to meet with one of our attorneys to complete your bankruptcy petition.&nbsp; During this appointment, you will sign your petition and complete your credit counseling.&nbsp; Your bankruptcy case cannot be filed on the same day in which you receive credit counseling.&nbsp; Therefore, in most cases, your bankruptcy petition will filed the following day.</p>
					<p className="lead">Will I have to go to court?</p>
					<p>Yes.&nbsp; There will be at least one hearing, called “Meeting of Creditors,” that you must attend.&nbsp; This hearing is usually scheduled about 30 days from the date you file your bankruptcy case.&nbsp; From time to time during the course of your bankruptcy, there may be other hearings which are scheduled.&nbsp; We will let you know if your attendance at these other hearings are necessary.</p>
					<p className="lead">How long does bankruptcy last?</p>
					<p>In general, the average length of time your bankruptcy case is active is approximately 90 days, from the time your petition is filed to the time the discharge order is entered by the Court.</p>
					<p className="lead">Can I file by myself or does my spouse have to file with me?</p>
					<p>No.&nbsp; While only one spouse may be interested in filing a bankruptcy case, it may be advantageous for both spouses to file together.&nbsp; If there is a lot of joint debt, if both of you owe on a vehicle or home which one of you wants to surrender, or if the other spouse also has a lot of debt, it may be better for both spouses to file a joint petition.</p>
					<p className="lead">Will a bankruptcy affect my credit report?</p>
					<p>Yes.&nbsp; In most cases, your credit score will be reduced once a bankruptcy case is filed.&nbsp; After you receive your discharge, however, the balances on the debts which you bankrupted on must show a zero balance on your credit report, with the exception of those debts which you have reaffirmed.&nbsp; Discharged debts incorrectly reported as having a balance may negatively affect your credit report.&nbsp; You should check your credit report after your bankruptcy case is over with to verify that the accounts all show a zero balance.&nbsp; If something is incorrect, you can contact our office for more information.</p>
					<p className="lead">What if there is a co-signer on a debt of mine?</p>
					<p>If there is a co-signer on a credit card, medical bill, personal loan, or other such bill, then the co-signer will be responsible for paying that bill once you have discharged your debt.&nbsp; This is also the case if there is a co-signer on a home mortgage or car note and you are surrendering the home or car.&nbsp; If, on the other hand, you are keeping the home or car, and you reaffirm the debt, then both you and the co-signer will remain obligated to pay the debt reaffirmed.</p>
					<p className="lead">Will bankruptcy stop a garnishment, lawsuit, foreclosure, or repossession?</p>
					<p>Generally speaking, yes.&nbsp; When you file your bankruptcy case, an automatic stay goes into effect.&nbsp; With some exceptions, the automatic stay prevents a creditor from continuing its collection efforts against you, such as filing a lawsuit, calling you, writing you, repossessing a vehicle, or foreclosing on a home.&nbsp; If a lawsuit has already been filed or if a garnishment is already pending, the automatic stay also stops the lawsuit and garnishment from continuing.</p>
					<p className="lead">Will I be able to own a home after bankruptcy?</p>
					<p>Generally speaking, yes.&nbsp; However, it is important to note that as a result of downturn in the housing market as well as the general economic recession, you may have to wait longer to purchase a house and you may also have to satisfy certain requirements to qualify for a home mortgage.</p>
					<p className="lead">What are the costs for filing bankruptcy?</p>
					<p>The filing fee is $299.00.&nbsp; The credit counseling fee is $50.00.&nbsp; The fee for obtaining your credit report is $20.00 per person.&nbsp; The attorney fee varies from case to case but is determined by the amount of time we will work on the case until you receive your discharge.&nbsp; The attorney fee, the filing fee, the credit counseling fee, and the credit report fee all mist be paid in full before your bankruptcy petition can be filed with the court.&nbsp; However payment plans are available.</p>
					<p className="lead">What do I have to do to get started?</p>
					<p>We will provide you with a list of items to gather together.&nbsp; These items are necessary for us to prepare your bankruptcy petition.&nbsp; Some of the items include, but are not limited to, 6 months of bank statements, 6 months of paystubs, all of your bills, credit reports, and tax returns.</p>
					<p>To get started immediately and to set up an appointment, contact us <strong><a title="Contact US" href="https://rwkattorneys.com/contact-us/">NOW</a></strong> by filling out the contact form on this webpage or by calling us at (<strong>205) 833-2589</strong>!</p>

				</Col>
			</Row>

		</Container>
	</Layout >
)